import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useOnboardingV2 } from '@/composables';
import { accountRepo, hResourcesRepo } from '@/repositories';
import { useOnboardingStore } from '@/stores';
import { ONBOARDING_STEPS, Onboarding, Route } from '@/types';
import type { HAsyncError, IHResource, OnboardingStatusData } from '@/types';
import {
  extractHAsyncErrorMessage,
  repeatWhile,
  toASCII,
} from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { getOnboardingWebsiteBuilderStatus } from '@/utils/services/onboarding';

const ONBOARDING_INTERVAL_TIMEOUT = 5000;
const HAPI_EXCEPTION_ONBOARDING_DOES_NOT_EXISTS = 'Onboarding does not exist';
const ONBOARDING_TYPE_WEBSITE_BUILDER = 'website_builder';

export const useOnboardingStatusCheckV2 = () => {
  const router = useRouter();
  const onboardingStore = useOnboardingStore();
  const { subscriptionId } = useOnboardingV2();

  //Resource used for builder onboarding status check
  const resource = ref<IHResource | null>(null);

  const isOnboardingStatusCompleted = (status: string) => {
    const completedStatuses: string[] = [
      Onboarding.CompletedStatus.ACTIVATED,
      Onboarding.CompletedStatus.COMPLETED,
    ];

    return completedStatuses.includes(status);
  };

  const isHapiOnboardingDoesNotExistError = (error: HAsyncError): boolean =>
    extractHAsyncErrorMessage(error) ===
    HAPI_EXCEPTION_ONBOARDING_DOES_NOT_EXISTS;

  const fetchResourceBySubscriptionId = async (subscriptionId: string) => {
    const [{ data }, resourceError] = await hResourcesRepo.getResources(
      { subscriptionId },
      { overrideCache: true },
    );

    if (resourceError) {
      throw new Error(extractHAsyncErrorMessage(resourceError));
    }

    const resource = data[0];

    if (!resource) {
      throw new Error("Onboarding complete error - 'resource fetch failed'");
    }

    return resource;
  };

  const fetchOnboardingStatus = async (
    subscriptionId: string,
    domain: string,
  ): Promise<OnboardingStatusData | null> => {
    const [{ data }, error] = await accountRepo.getOnboardingStatus({
      orderId: subscriptionId,
      domain,
    });

    if (isHapiOnboardingDoesNotExistError(error)) {
      return null;
    }

    if (error) {
      throw new Error(extractHAsyncErrorMessage(error));
    }

    if (typeof data === 'boolean') {
      return null;
    }

    return data;
  };

  const getOnboardingAccountStatus = async (
    subscriptionId: string,
    domain: string,
  ): Promise<OnboardingStatusData | null> => {
    const onboardingStatusData = await fetchOnboardingStatus(
      subscriptionId,
      domain,
    );

    if (!onboardingStatusData) {
      return null;
    }

    if (onboardingStatusData.type !== ONBOARDING_TYPE_WEBSITE_BUILDER) {
      return onboardingStatusData;
    }

    if (!resource.value) {
      resource.value = await fetchResourceBySubscriptionId(subscriptionId);
    }

    const [{ data: onboardingWebsiteBuilderStatus }, error] =
      await getOnboardingWebsiteBuilderStatus(
        resource.value,
        domain,
        onboardingStatusData,
      );

    if (error) {
      throw new Error(extractHAsyncErrorMessage(error));
    }

    return onboardingWebsiteBuilderStatus;
  };

  const shouldContinuePolling = (
    onboardingStatusData: OnboardingStatusData | null,
  ) => {
    //if onboarding completed success
    if (
      onboardingStatusData &&
      isOnboardingStatusCompleted(onboardingStatusData?.status)
    ) {
      onboardingStore.setState(ONBOARDING_STEPS.COMPLETE, onboardingStatusData);

      return false;
    }

    //all  cases continue
    return true;
  };

  const pollOnboardingUntilCompletion = async (
    ms: number = ONBOARDING_INTERVAL_TIMEOUT,
  ) => {
    try {
      return await repeatWhile(
        async () =>
          getOnboardingAccountStatus(
            subscriptionId,
            toASCII(onboardingStore.state.domain?.domain || ''),
          ),
        shouldContinuePolling,
        ms,
      );
    } catch (error) {
      errorLogger.logError((error as Error).message);
      router.push({ name: Route.Base.HOME });

      return null;
    }
  };

  return {
    pollOnboardingUntilCompletion,
  };
};

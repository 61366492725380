<script setup lang="ts">
import type { HAction } from './hpActionModel';

import HpIcon from '@/components/Icons/HpIcon.vue';

interface Props {
  action: HAction;
}

defineProps<Props>();

const getIconType = (iconType: string | undefined) => iconType || 'none';
</script>

<template>
  <div
    class="h-actions__image-holder"
    :class="{
      'd-flex': action.payload && !action.payload.imagePath,
    }"
  >
    <img
      v-if="action.payload && action.payload.imagePath"
      :src="action.payload.imagePath"
    />
    <HpIcon
      v-else-if="action.payload && action.payload.icon"
      class="h-actions__payload-icon"
      :icon="action.payload.icon"
      :width="24"
      :height="24"
      static-view-box
      v-bind="{ [getIconType(action.payload.iconType)]: true }"
    />
    <hp-status-icon v-else .status="action.status" />
  </div>
</template>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}

.h-actions {
  &__image-holder {
    margin-right: 8px;
  }

  &__payload-icon {
    align-self: baseline;
    height: auto;
  }
}
</style>

import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useRegistrationFormPrefills, useGlobals } from '@/composables';
import { hDomainsRepo } from '@/repositories';
import { useProfileStore, useHDomainResourceStore } from '@/stores';
import type { WhoIsProfile, IWhoIsField, WhoIsEntityType } from '@/types';
import { Route, TLDS, HDomains, AmplitudeEvent } from '@/types';
import { toASCII, getSldTld } from '@/utils/helpers';

const EXCLUDED_TLDS: string[] = [
  TLDS.AM,
  TLDS.BE,
  TLDS.DK,
  TLDS.FI,
  TLDS.LU,
  TLDS.PK,
  TLDS.CO_UK,
  TLDS.IN,
  TLDS.LT,
  TLDS.ES,
  TLDS.BR,
  TLDS.IT,
];
const ALLOWED_ROUTES = [Route.Domain.REGISTER_DOMAIN_RESOURCE];

export const useInstantDomainRegistration = () => {
  const route = useRoute();
  const router = useRouter();
  const { getRegularAccountPrefills } = useRegistrationFormPrefills();
  const { contact } = useProfileStore();
  const { isPro } = storeToRefs(useProfileStore());
  const { amplitudeV2 } = useGlobals();
  const store = useStore();
  const { hasUserSinglePendingDomainResource } = storeToRefs(
    useHDomainResourceStore(),
  );

  const getIsInstantRegistrationAllowed = async (domain: string) => {
    if (isPro.value) {
      return false;
    }

    const [sld, tld] = getSldTld(domain, {
      omitDot: true,
    });

    const [{ data }, error] = await hDomainsRepo.validateRegistration({
      domain: sld,
      tld,
    });

    const isAvailable =
      data && !Object.values(data).some((value) => value === 0);

    if (error || !isAvailable) {
      router.push({ name: Route.Domain.MY_DOMAINS });

      return false;
    }

    const isAllowed =
      hasUserSinglePendingDomainResource.value &&
      ALLOWED_ROUTES.includes(route.name as Route.Domain) &&
      !getIsExcludedTld(tld) &&
      route.query.instant === '1';

    const query = { ...route.query };
    delete query.instant;
    router.replace({ query });

    return isAllowed;
  };

  const getIsExcludedTld = (tld: string) =>
    EXCLUDED_TLDS.includes(tld) ||
    tld.endsWith(`.${TLDS.ES}`) ||
    tld.endsWith(`.${TLDS.BR}`);

  const handleInstantDomainRegistration = (
    domain: string,
    profileToReuse?: WhoIsProfile | null,
  ) => {
    const [sld, tld] = getSldTld(domain, {
      omitDot: true,
    });

    if (profileToReuse) {
      return handleInstantRegistrationReusingExistingProfile(
        profileToReuse,
        tld,
        sld,
      );
    }

    return handleInstantRegistrationUsingAccountDetails(tld, sld);
  };

  const handleInstantRegistrationReusingExistingProfile = async (
    profileToReuse: WhoIsProfile,
    tld: string,
    sld: string,
  ) => {
    const isTldSpecificWhoIsProfile = checkIsTldSpecificWhoIsProfile(
      tld,
      profileToReuse,
    );

    if (isTldSpecificWhoIsProfile) {
      return await registerDomain(tld, sld, profileToReuse.id);
    }

    return await createWhoIsProfileAndRegisterDomain({
      tld,
      sld,
      countryCode: profileToReuse.country,
      entityType: profileToReuse.entityType,
      prefillProfile: profileToReuse,
    });
  };

  const handleInstantRegistrationUsingAccountDetails = async (
    tld: string,
    sld: string,
  ) => {
    const { countryCode, companyName } = contact || {};

    if (!countryCode) {
      return null;
    }

    const entityType = companyName
      ? HDomains.WhoIsEntity.ORGANIZATION
      : HDomains.WhoIsEntity.INDIVIDUAL;

    return await createWhoIsProfileAndRegisterDomain({
      tld,
      sld,
      countryCode,
      entityType,
      prefillProfile: null,
    });
  };

  const createWhoIsProfileAndRegisterDomain = async ({
    tld,
    sld,
    countryCode,
    entityType,
    prefillProfile,
  }: {
    tld: string;
    sld: string;
    countryCode: string;
    entityType: WhoIsEntityType;
    prefillProfile: WhoIsProfile | null;
  }) => {
    const whoisFieldsList = await fetchWhoisFieldsList(
      tld,
      countryCode,
      entityType,
    );

    if (!whoisFieldsList) {
      return null;
    }

    const whoIsDetailsModel = prefillWhoIsDetailsModel(
      whoisFieldsList,
      countryCode,
      prefillProfile,
    );

    if (!whoIsDetailsModel) {
      return null;
    }

    const createdProfile = await createProfile(
      tld,
      whoIsDetailsModel,
      entityType,
    );

    if (!createdProfile) {
      return null;
    }

    return await registerDomain(tld, sld, createdProfile.id);
  };

  const fetchWhoisFieldsList = async (
    tld: string,
    countryCode: string,
    entityType: WhoIsEntityType,
  ) => {
    const [{ data }, err] = await hDomainsRepo.getWhoisRequirements(
      {
        tld,
        entityType,
        countryCode,
      },
      {
        hideToastr: true,
      },
    );

    if (err || !Array.isArray(data?.tldDetails)) {
      return null;
    }

    return Object.values(data.whoisDetails);
  };

  const prefillWhoIsDetailsModel = (
    whoisFieldsList: IWhoIsField[],
    countryCode: string,
    prefillProfile: WhoIsProfile | null = null,
  ) => {
    const whoIsDetailsModelPrefilled = getRegularAccountPrefills({
      country: countryCode,
      prefillProfile,
      whoIsFields: whoisFieldsList,
    });

    const isAllFieldsPrefilled =
      whoisFieldsList.length ===
      Object.values(whoIsDetailsModelPrefilled).length;

    if (!isAllFieldsPrefilled) {
      dispatchAmplitudeEventValidationFailed();

      return null;
    }

    return whoIsDetailsModelPrefilled;
  };

  const createProfile = async (
    tld: string,
    whoIsDetailsModel: Record<string, string>,
    entityType: WhoIsEntityType,
  ) => {
    const [{ data: createdProfile }] = await hDomainsRepo.storeWhoisProfile(
      {
        entityType,
        whoisDetails: whoIsDetailsModel,
        tld,
        tldDetails: [],
      },
      {
        hideToastr: true,
      },
    );

    if (!createdProfile) {
      return null;
    }

    store.commit('ADD_HDOMAIN_WHOIS_PROFILE', createdProfile);

    return createdProfile;
  };

  const registerDomain = async (
    tld: string,
    sld: string,
    profileId: number,
  ) => {
    const [{ data: registeredDomain }, error] =
      await hDomainsRepo.registerDomain(
        {
          tld,
          domain: toASCII(sld),
          domainContacts: {
            owner: profileId,
            admin: profileId,
            billing: profileId,
            tech: profileId,
          },
          additionalDetails: [],
        },
        {
          hideToastr: true,
        },
      );

    return {
      isSuccessful: !error,
      registeredDomain,
    };
  };

  const dispatchAmplitudeEventValidationFailed = () => {
    amplitudeV2(
      AmplitudeEvent.Domain.INSTANT_DOMAIN_REGISTRATION_VALIDATION_FAIL,
    );
  };

  const checkIsTldSpecificWhoIsProfile = (
    tld: string,
    profileToReuse: WhoIsProfile,
  ) => profileToReuse.tld === tld;

  return {
    getIsInstantRegistrationAllowed,
    handleInstantDomainRegistration,
  };
};

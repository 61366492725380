import type {
  Header,
  Vhost,
  IMigrationDomainVHost,
  RequestConfig,
  Hosting,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http, { EXTENDED_TIMEOUT } from '@/utils/services/http/httpService';

export default {
  restUrl: (v: string) => `${process.env.VITE_API_REST}/${v}/domains`,
  ssoUrl: `${process.env.VITE_API_SSO}/v1/domains`,
  ssoBaseUrl: process.env.VITE_API_SSO,
  integrations: process.env.VITE_API_INTEGRATIONS,

  // @ts-ignore
  async getIsDomainPointing(params, cancelToken) {
    return await hAsync<boolean>(
      http.get(`${this.restUrl('v3')}/is-pointing-for-client`, {
        timeout: EXTENDED_TIMEOUT,
        hideToastr: true,
        params,
        cache: 5 * 60,
        cancelToken,
      }),
    );
  },

  async getManagedVHostsList(params: {
    types?: Hosting.Vhost[];
    includePreviews?: 1 | 0;
    skipSubdomains?: 1 | 0;
  }) {
    return await hAsync<Vhost[]>(
      http.get(`${this.restUrl('v3')}/list-managed`, {
        params,
      }),
    );
  },

  // @ts-ignore
  async getPreviewLink(domain, params = {}) {
    return await hAsync(
      http.get(`${this.restUrl('v2')}/preview-link`, {
        domain,
        params: { domain },
        ...params,
      }),
    );
  },

  // @ts-ignore
  async setMainDomain(newDomain) {
    return await hAsync(
      http.patch(`${this.restUrl('v2')}/main-domain`, { newDomain }),
    );
  },
  async getDomainsList(includeWpList = true, activeDomainOnly = false) {
    return await hAsync(
      http.get(`${this.restUrl('v3')}/vhosts/list`, {
        params: {
          types: ['main', 'addon', 'subdomain'],
          include_stagings: 0,
          include_wordpress_list: includeWpList ? 1 : 0,
          active_domain_only: activeDomainOnly ? 1 : 0,
        },
      }),
    );
  },

  // @ts-ignore
  async getDomainsListByClientId(clientId) {
    return await hAsync<IMigrationDomainVHost[]>(
      http.get(`${this.restUrl('v3')}/list-by-client`, {
        params: {
          clientId,
        },
      }),
    );
  },
  async postPreviewLink() {
    return await hAsync(http.post(`${this.restUrl('v2')}/preview-link`));
  },
  async getSubdomains() {
    return await hAsync(http.get(`${this.restUrl('v3')}/subdomains`));
  },

  // @ts-ignore
  async addSubdomain(payload) {
    return await hAsync(http.post(`${this.restUrl('v3')}/subdomains`, payload));
  },

  async deleteSubdomain(config?: RequestConfig) {
    return await hAsync(
      http.delete(`${this.restUrl('v3')}/subdomains`, config),
    );
  },

  // @ts-ignore
  async isDomainPointing(params, domain, cacheConfig) {
    let config = {
      domain,
      params,
      hideToastr: true,
      timeout: EXTENDED_TIMEOUT,
    };
    if (cacheConfig) {
      config = { ...config, ...cacheConfig };
    }

    return await hAsync(http.get(`${this.restUrl('v2')}/is-pointing`, config));
  },

  // @ts-ignore
  async getDomainPointingIssues(params, domain, cacheConfig) {
    let config = {
      domain,
      params,
      hideToastr: true,
    };
    if (cacheConfig) {
      config = { ...config, ...cacheConfig };
    }

    return await hAsync(
      http.get(`${this.restUrl('v2')}/domain-pointing-issues`, config),
    );
  },
  async getParkedDomains() {
    return await hAsync(http.get(`${this.restUrl('v3')}/parked-domains`));
  },

  // @ts-ignore
  async addParkedDomain({ parkDomain, emailType }) {
    return await hAsync(
      http.post(`${this.restUrl('v3')}/parked-domains`, {
        parkDomain,
        emailType,
        checkOwnership: true,
      }),
    );
  },

  // @ts-ignore
  async deleteParkedDomain(parkDomain) {
    return await hAsync(
      http.delete(`${this.restUrl('v3')}/parked-domains`, {
        params: { parkDomain },
      }),
    );
  },

  // @ts-ignore
  async postOwnershipData(domain) {
    return await hAsync(
      http.post(`${this.restUrl('v3')}/ownership`, { domain }),
    );
  },

  // @ts-ignore
  async verifyPendingOwnership(domain) {
    return await hAsync(
      http.post(`${this.restUrl('v3')}/ownership/pending/verify`, { domain }),
    );
  },

  async addAddon(
    payload: {
      domain: string;
      password?: string;
      emailType?: string;
    },
    headers?: { [Header.USERNAME]: string; [Header.ORDER_ID]: string },
  ) {
    return await hAsync(
      http.post(`${this.restUrl('v3')}/addons`, payload, {
        headers,
      }),
    );
  },

  // @ts-ignore
  async deleteAddon(domain) {
    return await hAsync(
      http.delete(`${this.restUrl('v3')}/addons`, { params: { domain } }),
    );
  },

  // @ts-ignore
  async activateWppOrder(payload) {
    return await hAsync(
      http.post(`${this.ssoBaseUrl}/v1/privacy-protection/activate`, payload),
    );
  },

  // @ts-ignore
  async registerDomain(request) {
    return await hAsync(
      http.post(`${this.ssoUrl}/management/domains`, request),
    );
  },

  // @ts-ignore
  async getDomainInfo(domain) {
    return await hAsync(
      http.get(`${this.restUrl('v2')}/domain-info`, {
        domain,
        hostingHeaderRequired: true,
      }),
    );
  },

  async vhostIndex(params = {}) {
    return await hAsync(
      http.get(`${this.restUrl('v3')}/list-vhosts`, { params }),
    );
  },

  async getDomainProviderData(domain: string) {
    return await hAsync(
      http.get(
        `${this.integrations}/api/direct/domain-providers/find-domain-data`,
        {
          params: { domain },
        },
      ),
    );
  },

  async storeOtherDomainProvider(domain: string, provider: string) {
    return await hAsync(
      http.post(
        `${this.integrations}/api/direct/domain-providers/store-other-provider`,
        {
          domain,
          provider,
        },
      ),
    );
  },
};

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hResourcesRepo } from '@/repositories';
import type {
  HResourceState,
  IHResource,
  HResourceType,
  HAsyncError,
  RequestConfig,
} from '@/types';
import { HRESOURCES_STATE, HRESOURCES_TYPE } from '@/types';
import { isEmailResource } from '@/utils/helpers/emailsHelper';
import { isDateInPast } from '@/utils/helpers/timeHelpers';

export const useResourcesStore = defineStore('resourcesStore', () => {
  const resources = ref<IHResource[]>([]);
  const isResourcesLoading = ref(false);
  const isLoaded = ref(false);

  const domainResources = computed(() =>
    resources.value.filter(
      (resource) => resource.type === HRESOURCES_TYPE.DOMAIN,
    ),
  );

  const pendingHostingContainingFreeEmailSetup = computed(() =>
    resources.value.filter(
      (resource) =>
        resource.type === HRESOURCES_TYPE.HOSTING &&
        resource.state === HRESOURCES_STATE.PENDING,
    ),
  );

  const hostingResources = computed<IHResource[]>(() =>
    resources.value.filter(({ type }) => type === HRESOURCES_TYPE.HOSTING),
  );

  const activeHostingWebsites = computed(() => {
    const websitesResources = hostingResources.value.flatMap(
      ({ items }) => items,
    );

    return websitesResources.filter(
      (resource) => resource.state !== HRESOURCES_STATE.DELETED,
    );
  });

  const activeHostingResources = computed<IHResource[]>(() =>
    hostingResources.value.filter(
      ({ state }) => state === HRESOURCES_STATE.ACTIVE,
    ),
  );

  const activeDomainResources = computed(() =>
    domainResources.value.filter(
      (resource) => resource.state === HRESOURCES_STATE.ACTIVE,
    ),
  );

  const activeEmailResources = computed<IHResource[]>(() =>
    resources.value.filter(
      ({ type, state }) =>
        isEmailResource(type) && state === HRESOURCES_STATE.ACTIVE,
    ),
  );

  const isExpiredDomainResources = computed(() =>
    domainResources.value.some(({ expiresAt }) =>
      isDateInPast(expiresAt || ''),
    ),
  );

  const hasActiveOrder = computed(() =>
    resources.value.some(({ state }) => state === HRESOURCES_STATE.ACTIVE),
  );

  const billingResources = computed(() =>
    resources.value.filter(
      (resource) =>
        !(
          [
            HRESOURCES_STATE.DELETED,
            HRESOURCES_STATE.DELETING,
          ] as HResourceState[]
        ).includes(resource.state),
    ),
  );

  const h5gResources = computed(() =>
    resources.value.filter((resource) => resource.config?.h5g),
  );

  const fetchResources = async (
    {
      requestData,
      requestConfig,
    }: {
      requestData?: Record<string, unknown>;
      requestConfig?: RequestConfig;
    } = {
      requestData: {},
      requestConfig: { overrideCache: true },
    },
  ): Promise<[{ data: IHResource[] }, HAsyncError]> => {
    isResourcesLoading.value = true;
    const [{ data }, error] = await hResourcesRepo.getResources(
      requestData,
      requestConfig,
    );

    if (error) {
      isResourcesLoading.value = false;

      return [{ data }, error];
    }

    resources.value = data;
    isResourcesLoading.value = false;
    isLoaded.value = true;

    return [{ data }, error];
  };

  const updateResourceState = (id: number | string, state: HResourceState) => {
    const resource = getResourceById(id);

    if (resource?.state) {
      resource.state = state;

      resources.value = [
        ...resources.value.filter((it) => resource.id !== it.id),
        resource,
      ];
    }
  };

  const getResourceById = (id: number | string) =>
    resources.value.find((resource) => String(resource.id) === String(id));

  const getResourceBySubscriptionId = (subscriptionId: string) =>
    resources.value.find(
      (resource) => resource.chargebeeSubscriptionId === subscriptionId,
    );

  const getResourceByOrderId = (orderId: string) =>
    resources.value.find((resource) => resource.metadata?.orderId === orderId);

  const getDomainResourceByDomain = (domain: string) =>
    domainResources.value.find((resource) => resource.title === domain);

  const getDomainResourceByDomainAndStates = (
    domain: string,
    states: HResourceState[],
  ) =>
    domainResources.value.find(
      (resource) =>
        resource.title === domain && states.includes(resource.state),
    );

  const getResourceByIdempotencyKey = (key: string) =>
    resources.value.find((resource) => resource.idempotencyKey === key);

  const getResourceByReferenceId = (id: string) =>
    resources.value.find((resource) => resource.referenceId === id);

  const getResourcesByType = (key: HResourceType) =>
    resources.value.filter((resource) => resource.type === key);

  return {
    resources,
    isResourcesLoading,
    isLoaded,
    hostingResources,
    activeDomainResources,
    activeHostingWebsites,
    activeHostingResources,
    pendingHostingContainingFreeEmailSetup,
    isExpiredDomainResources,
    billingResources,
    domainResources,
    h5gResources,
    getDomainResourceByDomain,
    getResourceById,
    fetchResources,
    getResourceByOrderId,
    hasActiveOrder,
    getResourceBySubscriptionId,
    getResourceByIdempotencyKey,
    getResourceByReferenceId,
    getResourcesByType,
    activeEmailResources,
    getDomainResourceByDomainAndStates,
    updateResourceState,
  };
});

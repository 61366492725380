import cookies from 'js-cookie';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  useGlobals,
  useOnboardingStatusCheckV2,
  useOnboardingV2,
  useExperiment,
  useResourceMapper,
} from '@/composables';
import {
  accountRepo,
  authRepo,
  surveyRepo,
  websitesRepo,
} from '@/repositories';
import {
  useOnboardingStore,
  useOnboardingSourceStore,
  useResourcesStore,
  useOrdersStore,
} from '@/stores';
import type {
  SetupOnboardingReqParams,
  SetupOnboardingReqParamsWithoutAccountConfig,
  AddonOnboardingReqParams,
  Platform,
  ISurveyAnswer,
} from '@/types';
import {
  Cookie,
  PLATFORM,
  Route,
  CSAT_CUSTOM_IDENTIFIER,
  AmplitudeEvent,
  Experiment,
  AmplitudeLocation,
  HRESOURCES_STATE,
} from '@/types';
import {
  timeout,
  toASCII,
  mapKeyValue,
  assignLocationAsync,
} from '@/utils/helpers';
import { generateRandomDomainName } from '@/utils/helpers/domainsHelper';
import { errorLogger } from '@/utils/services/errorLogging';
import { APPS } from '@/utils/services/http/authRedirectService';
import { addRefetch } from '@/utils/services/localStorageRefetchService';

export const useOnboardingSubmit = () => {
  const {
    subscriptionId,
    is000WebhostMigration,
    isMigrationSelected,
    isAddonOnboarding,
    hasSelectedWordPressAppearance,
    wordPressAppearanceType,
    isCreationSource000Webhost,
    isBuilderPlatformSelected,
  } = useOnboardingV2();
  const { isExperimentActive: isWordPressRedirectExperimentActive } =
    useExperiment(Experiment.ID.WORDPRESS_REDIRECT_TO_WP_ADMIN);
  const { pollOnboardingUntilCompletion } = useOnboardingStatusCheckV2();
  const { getResourceBySubscriptionId } = useResourceMapper();
  const onboardingSourceStore = useOnboardingSourceStore();
  const { updateResourceState } = useResourcesStore();
  const { toastr, t, amplitudeV2 } = useGlobals();
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const onboardingStore = useOnboardingStore();
  const ordersStore = useOrdersStore();
  const domain = toASCII(onboardingStore.state.domain?.domain || '');
  const isWaitingForRedirectAfterOnboarding = ref(true);

  const wordpressAIGeneratedContent = computed(() => {
    if (!onboardingStore.state.wordpressAIContentGeneration) return;

    return {
      aiGenerateContent: onboardingStore.state.wordpressAIContentGeneration,
    };
  });

  const handleCompleteHostingOnboarding = async (redirect: boolean) => {
    if (redirect) {
      await handleRedirect();
    } else {
      await notifyCompleteOnboarding();
    }
  };

  const updateDataAfterOnboarding = async (resourceId: number) => {
    updateResourceState(resourceId, HRESOURCES_STATE.ACTIVE);

    await store.dispatch('fetchHostingOrders');
    await ordersStore.fetchManagedAndOwnedOrdersWithLimits();
  };

  const notifyCompleteSetup = (title: string, text: string) => {
    toastr.s(title, {
      text,
      buttons: [
        {
          callback: handleRedirect,
          props: { primary: true, text: true },
          text: 'Manage',
          externalLink: true,
        },
      ],
    });
  };

  const notifyCompleteOnboarding = async () => {
    const resource = getResourceBySubscriptionId(subscriptionId);
    const planTitle = resource?.metadata?.planTitle;

    if (resource?.id) {
      await updateDataAfterOnboarding(resource.id);
    }

    if (isAddonOnboarding.value) {
      return notifyCompleteSetup(
        t('v2.notification.website.added'),
        t('v2.notification.website.added.subtitle'),
      );
    }

    return notifyCompleteSetup(
      t('Your {planTitle} plan is now active', { planTitle }),
      t('You can manage and edit your website'),
    );
  };

  const pollAndCompleteOnboarding = async () => {
    await pollOnboardingUntilCompletion();

    await amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_COMPLETED, {
      completionStatus: 'successfully',
    });

    onboardingStore.setIsOnboardingOngoing(false);

    if (is000WebhostMigration.value) {
      await complete000webhostOnboarding();
    } else {
      await handleCompleteHostingOnboarding(
        isWaitingForRedirectAfterOnboarding.value,
      );
    }
  };

  const sendOnboardingSummaryFinishedToAmplitude = async () => {
    const {
      wordpressPluginsSelect,
      platformSelect,
      domain,
      wordpressAIContentGeneration,
      wordPressPreviewAstraTemplate,
      typeOfWebsite,
      wordpressThemeSelect,
    } = onboardingStore.state;

    const hasPlugins = !!wordpressPluginsSelect?.filter((plugin) => !!plugin)
      ?.length;

    const amplitudeProperties = {
      selectedPlatform: platformSelect,
      selectedDomainType: domain?.tag,
      aiContentGenrated: !!wordpressAIContentGeneration,
      theme: wordpressThemeSelect,
      subscriptionId: route.params.order_id,
      starterTemplate: wordPressPreviewAstraTemplate?.slug,
      pageBuilder: wordPressPreviewAstraTemplate?.pageBuilder,
      is000WebhostOnboarding: isCreationSource000Webhost.value,
      websiteType: typeOfWebsite,
      ...(hasPlugins && {
        plugins: wordpressPluginsSelect,
      }),
      ...(hasSelectedWordPressAppearance.value && {
        appearance: wordPressAppearanceType.value,
      }),
    };

    await amplitudeV2(
      AmplitudeEvent.Onboarding.SUMMARY_FINISHED,
      amplitudeProperties,
    );
  };

  const isNoQuestionAnswered = computed(
    () =>
      onboardingStore.state.surveyForWho === undefined &&
      onboardingStore.state.typeOfWebsite === undefined,
  );

  const getSurveyAnswersForOnboarding = () => {
    if (isNoQuestionAnswered.value) {
      return {
        skippedOnboarding: true,
      };
    }

    const websiteFor = onboardingStore.state.surveyForWho;
    const websiteType = onboardingStore.state.typeOfWebsite;

    return {
      skippedOnboarding: false,
      websiteFor,
      websiteType,
    };
  };

  const getSurveyAnswersForHSurveys = () => {
    const websiteFor = onboardingStore.state.surveyForWho;
    const websiteType = onboardingStore.state.typeOfWebsite;

    return [
      {
        questionSlug: 'website_for',
        answer: websiteFor || '',
      },
      {
        questionSlug: 'website_type',
        answer: websiteType || '',
      },
    ].filter((answer) => answer.answer) as ISurveyAnswer[];
  };

  const handleRedirect = async () => {
    await store.dispatch('fetchHostingOrders');

    // redirect to migrations onboarding
    if (isMigrationSelected.value) {
      return await router.push({
        name: Route.MigrationsOnboarding.BASE_PATH,
        params: { domain },
      });
    }

    // redirect to website dashboard if empty website
    if (!onboardingStore.state.platformSelect) {
      return await router.push({
        name: Route.Websites.WEBSITE_DASHBOARD,
        params: { domain },
      });
    }

    // webpro shorter onboarding
    if (onboardingStore.isShorterWebproOnboarding) {
      return await router.push({
        name: Route.Websites.WEBSITE_DASHBOARD,
        params: { domain },
        query: { cSat: CSAT_CUSTOM_IDENTIFIER.ONBOARDING_WEB_PRO },
      });
    }

    // redirect to website dashboard if wordpress website
    if (onboardingStore.state.platformSelect === PLATFORM.WORDPRESS) {
      const redirectToDashboard = async () => {
        await amplitudeV2(AmplitudeEvent.Hosting.DASHBOARD_ENTER, {
          location: AmplitudeLocation.Base.ONBOARDING,
        });

        await router.push({
          name: Route.Websites.WEBSITE_DASHBOARD,
          params: { domain },
          query: { cSat: CSAT_CUSTOM_IDENTIFIER.ONBOARDING_V2 },
        });
      };

      if (
        !isWordPressRedirectExperimentActive.value &&
        !cookies.get(Cookie.ONBOARDING_WP_REDIRECT)
      ) {
        return await redirectToDashboard();
      }

      await onboardingStore.fetchOnboardingCompletionStatus({
        domain,
        orderId: route.params.order_id as string,
      });

      const autoLoginLink =
        onboardingStore.onboardingCompletionStatus?.autoLoginLink;

      if (autoLoginLink) {
        window.location.href = autoLoginLink;

        await timeout(10000); // wait for auto login to finish and don't clear the state

        return;
      }

      return await redirectToDashboard();
    }

    return await router.push({ name: Route.Base.HOME });
  };

  const submitBuilderOnboarding = async (options?: {
    hostingReferenceId?: string;
  }) => {
    const hostingReferenceId =
      options?.hostingReferenceId ||
      getResourceBySubscriptionId(subscriptionId)?.referenceId ||
      '';
    const domainToRedirect = onboardingStore.state.domain?.domain;
    const txtRecord = onboardingStore.state.domain?.txtToVerify || '';

    const answers = getSurveyAnswersForHSurveys();
    const hasAnswers = answers.length > 0;

    if (onboardingStore.isEligibleForSurvey && hasAnswers) {
      await surveyRepo.postSurveyResponse({ answers });
    }

    await amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_COMPLETED, {
      completionStatus: 'successfully',
    });

    await redirectToBuilder({
      hostingReferenceId,
      domainToRedirect,
      txtRecord,
    });
  };

  const redirectToBuilder = async ({
    hostingReferenceId,
    txtRecord,
    domainToRedirect,
  }: {
    hostingReferenceId: string;
    txtRecord?: string;
    domainToRedirect?: string;
  }) => {
    const builderUrl = new URL(
      `${process.env.VITE_API_BUILDER_URL}/ai-builder`,
    );

    const [{ data: tokenRequest }, error] = await authRepo.generateToken(
      APPS.BUILDER,
    );

    if (error) {
      throw error;
    }

    const searchParams = new URLSearchParams({
      hostingReferenceId,
      domain: domainToRedirect || generateRandomDomainName(true),
      jwt: tokenRequest.token,
      ...(txtRecord && { txtRecord }),
    });

    const redirectUrl = `${builderUrl}?${searchParams.toString()}`;

    window.history.pushState({}, '', `${window.location.origin}/websites`);
    await assignLocationAsync(redirectUrl);
  };

  const setupOnboarding = async (
    params?: SetupOnboardingReqParamsWithoutAccountConfig,
  ) => {
    //todo  add FileStorageUrl and cover migration if needed
    const onboarding = onboardingStore.state;

    const body = {
      accountConfig: {
        domain,
        datacenter: onboarding.serverSelect || '',
      },
      ...params,
    };

    const [_, error] =
      (await submitSetupOnboarding(body, subscriptionId)) || [];

    if (error) {
      throw error;
    }
  };

  const submitAddonOnboarding = async (
    requestBody: AddonOnboardingReqParams,
    subscriptionId: string,
  ) => {
    const resource = getResourceBySubscriptionId(subscriptionId);

    if (!resource?.referenceId || !resource?.config) {
      router.push({ name: Route.Base.HOME });
      toastr.e(t('Order not found'));
      throw new Error('Addon onboarding missing resource');
    }

    const [_, error] = await websitesRepo.submitAddonOnboarding(requestBody, {
      username: route.params.username,
      orderId: resource.referenceId,
      domain: resource.config.domain,
    });

    if (error) {
      throw error;
    }
  };

  const submitSetupOnboarding = async (
    body: SetupOnboardingReqParams,
    subscriptionId: string,
  ) => {
    const resource = getResourceBySubscriptionId(subscriptionId);

    if (!resource || !resource.referenceId) {
      router.push({ name: Route.Base.HOME });
      errorLogger.logError('Setup onboarding Missing resource');
      toastr.e(t('Order not found'));

      return;
    }

    const survey = getSurveyAnswersForOnboarding();

    return await accountRepo.setupOnboarding(
      {
        ...body,
        ...(onboardingStore.isEligibleForSurvey
          ? {
              survey,
            }
          : {}),
        orderId: subscriptionId,
        accountConfig: {
          ...body.accountConfig,
        },
      },
      resource.referenceId,
    );
  };

  const submitOnboarding = async (platform?: Platform) => {
    await sendOnboardingSummaryFinishedToAmplitude();
    if (
      !isWordPressRedirectExperimentActive.value &&
      !cookies.get(Cookie.ONBOARDING_WP_REDIRECT)
    ) {
      clearStateBeforeUnload();
    }

    if (isBuilderPlatformSelected.value) {
      await submitBuilderOnboarding();
    } else {
      const PLATFORM_SUBMIT_MAP = {
        [PLATFORM.WORDPRESS]: () => submitWordpressOnboarding(),
        default: () => submitDefaultOnboarding(),
      };

      await mapKeyValue(
        PLATFORM_SUBMIT_MAP,
        platform || onboardingStore.state.platformSelect || 'other',
      )();

      const resourceId = getResourceBySubscriptionId(subscriptionId)?.id;
      if (resourceId && !isAddonOnboarding.value) {
        updateResourceState(resourceId, HRESOURCES_STATE.ACTIVATING);
      }

      await pollAndCompleteOnboarding();
    }

    onboardingStore.$reset();
  };

  const submitDefaultOnboarding = () => {
    if (isAddonOnboarding.value) {
      return submitAddonOnboarding(
        {
          domain,
          orderId: subscriptionId,
        },
        subscriptionId,
      );
    }

    return setupOnboarding();
  };

  const clearStateBeforeUnload = () => {
    window.onbeforeunload = () => {
      addRefetch('fetchHostingOrders');
      onboardingStore.$reset();
    };
  };

  const submitWordpressOnboarding = () => {
    if (isAddonOnboarding.value) {
      const requestBody = {
        domain,
        type: PLATFORM.WORDPRESS,
        onboardingConfig: {
          application: PLATFORM.WORDPRESS,
          email: onboardingStore.state.wordpressAdminSetup.email,
          language: onboardingStore.state.wordpressAdminSetup.language,
          login: onboardingStore.state.wordpressAdminSetup.email,
          password: onboardingStore.state.wordpressAdminSetup.password,
          plugins: onboardingStore.state.wordpressPluginsSelect,
          theme: onboardingStore.state.wordpressThemeSelect,
          themeLayout: onboardingStore.state.hostingerThemeSelect?.layoutId,
          themePalette: onboardingStore.state.hostingerThemeSelect?.paletteId,
          templateId: onboardingStore.state.wordPressPreviewAstraTemplate?.id,
          ...wordpressAIGeneratedContent.value,
        },
      };

      return submitAddonOnboarding(requestBody, subscriptionId);
    }

    return setupOnboarding({
      themeConfig: {
        slug: onboardingStore.state.wordpressThemeSelect as string,
      },
      ...(onboardingStore.state.wordPressPreviewAstraTemplate && {
        templateConfig: {
          id: onboardingStore.state.wordPressPreviewAstraTemplate.id,
          templateSlug:
            onboardingStore.state.wordPressPreviewAstraTemplate.slug,
        },
      }),
      autoinstallerConfig: {
        login: onboardingStore.state.wordpressAdminSetup.email,
        email: onboardingStore.state.wordpressAdminSetup.email,
        password: onboardingStore.state.wordpressAdminSetup.password,
        language: onboardingStore.state.wordpressAdminSetup.language,
        application: onboardingStore.state.platformSelect as string,
        plugins: onboardingStore.state.wordpressPluginsSelect,
      },
      ...wordpressAIGeneratedContent.value,
    });
  };

  const complete000webhostOnboarding = async () => {
    await store.dispatch('fetchHostingOrders');

    const resource = getResourceBySubscriptionId(subscriptionId);

    if (!onboardingSourceStore.selectedSource) {
      return router.push({ name: Route.Base.HOME });
    }

    const [, error] = await accountRepo.webhostTransfer({
      domain: onboardingStore.state.domain?.domain ?? '',
      hostname: onboardingSourceStore.selectedSource ?? '',
      username: resource?.config?.username ?? '',
      orderId: resource?.referenceId ?? '',
    });

    if (error) {
      throw error;
    }

    await amplitudeV2(AmplitudeEvent.Onboarding.ONBOARDING_COMPLETED, {
      completionStatus: 'successfully',
    });

    return router.push({ name: Route.Websites.MIGRATIONS_REQUESTS });
  };

  return {
    isWaitingForRedirectAfterOnboarding,
    submitSetupOnboarding,
    submitOnboarding,
    redirectToBuilder,
  };
};

import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import {
  useExperiment,
  useOnboardingSteps,
  useOnboardingV2,
} from '@/composables';
import { wordpressRepo, accountRepo } from '@/repositories';
import { useHostingerProStore, useProfileStore } from '@/stores';
import type {
  ArrayElementType,
  IWordPressTemplate,
  OnboardingCompletionData,
} from '@/types';
import { Experiment, STORE_PERSISTENT_KEYS } from '@/types';
import type {
  OnboardingState,
  OnboardingStep,
  WordPressWebsiteTypeAnswer,
} from '@/types/models/onboardingV2Models';
import {
  ONBOARDING_STEPS,
  H5G_ONBOARDING_STEPS_ARRAY,
  ONBOARDING_STEPS_ARRAY,
  ONBOARDING_STEPS_WITHOUT_SURVEY,
  SHORTER_WEBPRO_ONBOARDING_STEPS_ARRAY,
} from '@/types/models/onboardingV2Models';
import { errorLogger } from '@/utils/services/errorLogging';

export const useOnboardingStore = defineStore(
  'onboardingStore',
  () => {
    const state = ref({} as OnboardingState);
    const isEligibleForSurvey = ref(true);
    const onboardingCompletionStatus = ref<OnboardingCompletionData | null>(
      null,
    );
    const isUserNotifiedAboutAI = ref(false);
    const hasTriedAIFeature = ref(false);
    const wordPressTemplates = ref<
      Record<WordPressWebsiteTypeAnswer, IWordPressTemplate[]> | undefined
    >(undefined);

    const isOnboardingOngoing = ref(false);

    const isAddon = ref(false);
    const { isExperimentActive: isShortOnboardingExperimentActive } =
      useExperiment(Experiment.ID.ONBOARDING_WEBPRO_SHORT);
    const hostingerProStore = useHostingerProStore();
    const { canUsePresetsInOnboarding, presetsList } =
      storeToRefs(hostingerProStore);
    const profileStore = useProfileStore();
    const { isAccessManager } = storeToRefs(profileStore);
    const { isH5GOnboarding } = useOnboardingV2();

    const isIdentifiedPro = computed(
      () =>
        state.value[ONBOARDING_STEPS.SURVEY_FOR_WHO] === 'others' ||
        profileStore.isPro,
    );

    const isShorterWebproOnboarding = computed(() => {
      const createOrMigrate = state.value[ONBOARDING_STEPS.CREATE_OR_MIGRATE];

      if (createOrMigrate === 'migrate') return false;

      const platform = state.value[ONBOARDING_STEPS.PLATFORM_SELECT];

      if (platform !== 'wordpress') return false;

      return (
        isShortOnboardingExperimentActive.value &&
        isIdentifiedPro.value &&
        !isAccessManager.value
      );
    });

    const onboardingSteps = computed(() => {
      if (isH5GOnboarding.value) {
        return H5G_ONBOARDING_STEPS_ARRAY;
      }

      if (isShorterWebproOnboarding.value) {
        return SHORTER_WEBPRO_ONBOARDING_STEPS_ARRAY;
      }

      const steps = isEligibleForSurvey.value
        ? ONBOARDING_STEPS_ARRAY
        : ONBOARDING_STEPS_WITHOUT_SURVEY;

      return canUsePresetsInOnboarding.value && presetsList.value.length > 0
        ? steps
        : steps.filter((step) => step !== ONBOARDING_STEPS.WORDPRESS_PRESETS);
    });

    const currentStepValue = computed(() => state.value[currentStep.value]);

    const setState = <
      Key extends OnboardingStep,
      Value extends OnboardingState[Key],
    >(
      step: Key,
      value: Value,
    ) => {
      setIsOnboardingOngoing(true);

      state.value[step] = value;
    };

    const removeState = (step: OnboardingStep) => {
      delete state.value[step];
    };

    const setIsEligibleForSurvey = (value: boolean) => {
      isEligibleForSurvey.value = value;
    };

    const setIsAddon = (value: boolean) => {
      isAddon.value = value;
    };

    const setIsOnboardingOngoing = (value: boolean) => {
      isOnboardingOngoing.value = value;
    };

    const fetchOnboardingCompletionStatus = async ({
      domain,
      orderId,
    }: {
      domain: string;
      orderId: string;
    }) => {
      const [{ data }, err] = await accountRepo.getOnboardingCompletionStatus({
        orderId,
        domain,
      });

      if (err) {
        errorLogger.logError(err);

        return;
      }

      onboardingCompletionStatus.value = data;
    };

    const fetchWordPressTemplates = async () => {
      const [{ data }, err] = await wordpressRepo.getAstraTemplates();
      if (err || !data) return;

      wordPressTemplates.value = data;
    };

    const $reset = () => {
      currentStep.value = ONBOARDING_STEPS.SURVEY_FOR_WHO;
      completedSteps.value = [];
      state.value = {} as OnboardingState;
      transition.value = 'slide-left';
      isAddon.value = false;
      setIsOnboardingOngoing(false);
    };

    const {
      currentStep,
      completedSteps,
      previousStep,
      hasPreviousSteps,
      goToStep,
      goToPrevious,
      completionPercentage,
      removeCompletedStep,
      removeAllCompletedSteps,
      setStep,
      transition,
      setTransition,
      addCompletedStep,
      removePreviousStep,
    } =
      useOnboardingSteps<ArrayElementType<typeof onboardingSteps.value>>(
        onboardingSteps,
      );

    return {
      goToStep,
      goToPrevious,
      transition,
      fetchOnboardingCompletionStatus,
      currentStep,
      completedSteps,
      wordPressTemplates,
      currentStepValue,
      previousStep,
      hasPreviousSteps,
      completionPercentage,
      state,
      isEligibleForSurvey,
      isAddon,
      isOnboardingOngoing,
      onboardingCompletionStatus,
      setIsAddon,
      setIsOnboardingOngoing,
      isShorterWebproOnboarding,
      isIdentifiedPro,
      setState,
      setIsEligibleForSurvey,
      removeState,
      removeCompletedStep,
      removeAllCompletedSteps,
      isUserNotifiedAboutAI,
      hasTriedAIFeature,
      setStep,
      addCompletedStep,
      removePreviousStep,
      setTransition,
      fetchWordPressTemplates,
      $reset,
    };
  },
  {
    persist: {
      key: STORE_PERSISTENT_KEYS.ONBOARDING_STORE,
      storage: sessionStorage,
    },
  },
);
